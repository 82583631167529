.window {
  width: 300px;
  height: 650px;
  background: #FFF;
  position: relative;
  overflow: hidden;
  border: 8px solid #000;
  border-radius: 34px;
  box-shadow: 0 0 50px rgba(0,0,0,0.2);

  &:after {
    content: '';
    display: block;
    height: 12px;
    background: #000;
    width: 50%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    top: 0; left: 0; right: 0;
    margin: auto;
    z-index: 310;
  }
}

img.appElements {
  width: 300px;
  position: absolute;
  left: 0;
  margin: 0;
  z-index: 300;
}

img.windowTop {
  top: 0;
  z-index: 300;
}

img.windowBottom {
  bottom: 0;
  z-index: 300;
}

img.windowContent {
  top: 0;
  z-index: 290;
}
