@import "../variables";

.window {
  width: 100%;
  background: #000;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  overflow: hidden;
  font-family: 'Helvetica Neue';
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  max-width: calc(100% - 20px);

  @include when-big() {
    max-width: none;
  }
}

.platformDescription {
  @include when-big {
    margin-left: 40px;
  }
}
