@import "../variables";

.featuresList {
  margin: 0;
  padding: 0;

  ul, li {
    list-style: none;
  }

  li {
    margin-bottom: 16px;
  }

  li:before {
    content: '›';
    margin-right: 10px;
    font-weight: bold;
  }
}

.pageInner {
  min-height: 500px;
  text-align: center;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @include page-width;
  @include when-big() {
    margin-bottom: 70px;
  }
}

.heading {
  font-size: 2em;
  font-weight: 100;
  margin: 0 20px;

  &:after {
    content: '';
    display: block;
    width: 400px;
    height: 1px;
    background-color: rgba($text-color, 0.3);
    margin: 30px auto;
  }

  h2 {
    font-size: 1em;
    line-height: 1em;
    margin-bottom: 10px;
  }

  h1 {
    background-image: linear-gradient(180deg, #A31CBA 0%, #405BE4 100%);
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-size: 1.4em;
    line-height: 1.4em;
  }
}
