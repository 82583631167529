$brk: 1300px;
$text-color: #5e5e5e;
$brand-color: #000;

@mixin page-width() {
  width: 100%;

  @media screen and (min-width: $brk) {
    width: $brk;
    margin: 0 auto;
  }
}

@mixin when-big() {
  @media screen and (min-width: $brk) {
    @content;
  }
}

@mixin till-big() {
  @media screen and (max-width: $brk) {
    @content;
  }
}
