@import "../variables";

.list {
  list-style: none;
  margin: 0; padding: 0;
  display: flex;

  li {
    display: block;
    text-align: left;
  }
}

.platforms {
  flex-direction: row;
  margin-top: 20px;

  li {
    margin: 0 20px;

    @include when-big {
      margin: 0 20px 0 0;
    }

    border-radius: 20px;
    padding: 10px 25px;
    box-shadow: 0 0 50px rgba(0,0,0,0.2);
    background: #3ca129;
    color: #FFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 1em;
    line-height: 1em;
    position: relative;
    text-align: center;

    svg {
      margin-right: 10px;
    }
  }
}

.features {
  flex-direction: column;
  margin-top: 20px;
  line-height: 1.6;

  li {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    letter-spacing: 0.5px;

    svg {
      margin-right: 10px;
      color: #3ca129;
      min-width: 16px;
    }
  }
}

.comingSoon {
  position: absolute;
  top: -16px;
  right: -15px;
  font-size: 10px;
  background: #5d49d8;
  border-radius: 20px;
  padding: 4px 10px;
}
