@import "../variables";

.window {
  width: 700px;
  height: 400px;
  background: #000;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  overflow: hidden;
  font-family: 'Helvetica Neue';
  box-shadow: 0 0 50px rgba(0,0,0,0.2);

  @include till-big {
    transform: scale(0.6);
  }

  &Sidebar {
    width: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #5e5e5e;

    &Item {
      width: 50px;
      height: 50px;
      margin: 10px 10px;
      border-radius: 25px;
      background: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4bade1;
      color: #FFF;

      img {
        width: 100%;
        height: 100%;
        //overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
      }
    }
  }

  &MainContent {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    margin: 0px;

    ul {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      margin: 0;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}

.list {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  li {
    background: $text-color;
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    text-align: left;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      margin-right: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0;
      }
    }
  }
}

.tiles {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: hidden;

  li {
    color: #FFF;
    width: 100px;
    height: 130px;
    margin: 10px;

    i {
      display: block;
      border-radius: 10px;
      width: 100px;
      height: 100px;
      background: $text-color;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0;
      }
    }

    span {
      display: block;
      margin-top: 4px;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;

  &Content {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    margin: 0px 10px;
    width: 40px;
    position: relative;
    z-index: 300;
    padding: 10px 25px;
    border-radius: 10px;

    span {
      font-size: 0.8em;
      font-weight: bold;
    }
  }
}

.platformDescription {
  @include when-big {
    margin-right: 40px;
  }
}
