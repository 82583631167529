@import "variables";

.page {
  color: $text-color;
  font-size: 16px;
  line-height: 1.8;

  @include when-big() {
    font-size: 20px;
    line-height: 1.5;
  }
}

.pageInner {
  min-height: 500px;
  text-align: center;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include page-width;

  margin-bottom: 40px;

  @include when-big() {
    margin-bottom: 70px;
  }
}

.container {
  @include page-width();
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.platformDemo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include when-big {
    display: block;
    padding: 0;
    width: 50%;
  }
}

.platformDescription {
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  text-align: center;
  align-items: center;
  box-sizing: border-box;

  @include when-big {
    padding: 0;
    width: 50%;
    align-items: flex-start;
    text-align: left;
  }

  &Text {
    @include till-big {
      padding: 0 10px;
    }

    p {
      margin: 16px 0;
      font-size: 1em;
      line-height: 1.4;
    }
  }

  &Heading {
    font-size: 2em;
    font-weight: 100;
    margin: 0 0 20px;

    &:after {
      content: '';
      display: block;
      width: 400px;
      height: 1px;
      background-color: rgba($text-color, 0.3);
      margin: 30px auto 0;

      @include when-big {
        margin: 30px 0 0 0;
      }
    }

    h2 {
      font-size: 1em;
      line-height: 1em;
      margin-bottom: 10px;
    }

    h1 {
      background-image: linear-gradient(180deg, #A31CBA 0%, #405BE4 100%);
      background-size: cover;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0;
      font-size: 1.4em;
      line-height: 1.4em;
    }
  }
}

.mobileApp {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  margin-top: 80px;
  justify-content: center;

  @include when-big() {
    text-align: left;
    flex-direction: row;
    align-items: center;
  }
}

.autoApp {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  justify-content: center;

  @include when-big() {
    text-align: left;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.tvApp {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  justify-content: center;

  @include when-big() {
    text-align: left;
    flex-direction: row;
    align-items: center;
  }
}

.platformList {
  @include till-big {
    padding: 0 10px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar {
  background-color: $brand-color;
  color: #fff;
  padding: 20px;
  border-bottom: 1px solid #000;
  box-sizing: border-box;
  position: fixed;
  z-index: 3000;
  width: 100%;
  top: 0;
  left: 0;
}

.headerInner {
  @include page-width();
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }

  &Buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    box-sizing: border-box;
  }
}

.headerButton {
  box-sizing: border-box;
  margin-left: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  color: $brand-color !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #2b7a2c;
    color: #fff !important;
  }
}

.logo {
  height: 40px;
}

.hero {
  height: 850px;
  color: #FFF;
  position: relative;
  margin-top: 60px;
  background: #000;

  @include when-big() {
    background-position: right;
  }

  &:after {
    @include till-big {
      content: '';
      display: block;
      background: rgba(#000, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.heroInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 1s;
}

.heroCoverImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, 0.5);
}

.heroContent {
  @include page-width();

  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

  z-index: 999;

  align-items: center;

  @include when-big() {
    align-items: flex-end;
  }

  h1, h2 {
    margin: 3px;
    padding: 0;
  }

  img {
    margin-top: 20px;
  }
}

.bigLogo {
  height: 100px;
  margin-left: 6px;
}

.heroContentTexts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.1em;

  h2 {
    font-weight: 100;
    font-size: 20px;
    line-height: 1.3;
  }

  h1 {
    font-size: 40px;
    line-height: 1.3;
    font-weight: bold;
  }

  @include when-big {
    h2 {
      font-weight: 100;
      font-size: 20px;
      line-height: 1.3;
    }

    h1 {
      font-size: 59px;
      line-height: 1.3;
      font-weight: bold;
    }
  }



  h2, h1, p {
    margin: 10px 0;
  }

  @include when-big() {
    width: 800px;
    align-items: flex-start;
    text-align: left;
  }
}

.page {
  position: relative;
  padding-top: 80px;

  &:before {
    display: block;
    content: "";
    transform: scaleY(-1);
    background-image: linear-gradient(180deg, rgba(254, 254, 254, 0.00) 0%, rgba(0, 0, 0, 0.25) 100%);
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}



.cover {
  margin-top: 15px;
}

.features {
  font-size: 1em;
  font-weight: 300;
  margin: 50px 20px 40px 20px;
  text-align: center;

  @include when-big() {
    width: 70%;
  }
}

.s-bio {
  margin-bottom: 60px;
}

.contact {
  margin-top: 170px;
  background-color: #5e5e5e;
  background-image: url('./assets/bg-bottom.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &:after {
    content: '';
    display: block;
    background: rgba(#000, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.contactForm {
  position: relative;
  z-index: 999;
  background-color: #56B71D;
  border-radius: 20px;
  color: #FFF;
  text-align: left;
  padding: 20px 20px;
  font-size: 1em;
  margin-top: -100px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 0px 60px rgba(#FFF, 0.3);
  border: 1px solid rgba(#FFF, 0.2);

  @media screen and (min-width: 800px) {
    width: 800px;
    padding: 20px 40px;
  }

  > * {
    margin: 10px 0;
  }

  h2 {
    margin-bottom: 40px;
  }

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
}

.bottom {
  margin-bottom: 0;
  padding-bottom: 0;
  height: 600px;
  position: relative;
}

.cover2 {
  width: 80%;

  @include till-big {
    width: 100%;
    max-width: 100%;
  }
}


.contactRow {
  display: flex;
  flex-direction: column;

  @include when-big() {
    flex-direction: row;
  }
}

.contactCol {
  display: flex;
  flex-direction: column;

  @include when-big() {
    width: 50%;
  }
}

.copy {
  position: absolute;
  bottom: 10px;
  left: 0; right: 0;
  margin: auto;
  @include page-width();
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.7em;
  display: flex;
  z-index: 400;

  flex-direction: column;
  @include when-big() {
    flex-direction: row;
  }

  a {
    color: inherit;
  }

  &Col {
    @include when-big() {
      width: 50%;
    }

    &:first-child {
      text-align: center;

      @include when-big() {
        text-align: left;
      }
    }

    &:last-child {
      text-align: center;

      @include when-big() {
        text-align: right;
      }
    }
  }
}
